import React from 'react'

type Props = {
  color?: string 
}

const CalendarRegular: React.FC<Props> = ({ color }) => (
  <svg id="calendar-regular" width="30px" height="30px" viewBox="0 0 30 30">
    <g transform="translate(-362 -2377)"><rect width="30" height="30" transform="translate(362 2377)" fill={color}/></g>
  </svg>
)

export default React.memo<Props>(CalendarRegular)
import React, { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'

type Props = {
  setLocale: (locale: string) => void
}

const Navbar: React.FC<Props> = ({ setLocale }) => {

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex justify-end h-16">
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button
                  type="button"
                  className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  onClick={() => setLocale('nl')}
                >
                  NL
                </button>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  onClick={() => setLocale('fr')}
                >
                  FR
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  )
}

export default React.memo<Props>(Navbar)
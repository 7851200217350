import React, { useEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import styled from 'styled-components'
import moment from 'moment'
import times from 'lodash/times'
import {
  isPast,
  isToday,
  getDate,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  isSameMonth
} from 'date-fns'
import Navbar from './components/navbar'
import 'moment/locale/fr'
import 'moment/locale/nl'
import Legend from './components/legend'
import Footer from './components/footer'
import logo from './images/logo_phl@2x.png'
import logoFunticket from './images/funtick.jpeg'
import logoBvl from './images/logo-webshop-bvl.png'
import logoGva from './images/logo-webshop-gva.png'
import logoNb from './images/logo-webshop-nb.png'
import winter from './images/wintertraum.png'

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0.6rem;
`

const Weekdays = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 24px;
  padding-left: 6px;
  padding-right: 6px;
`

const Weekday = styled.div`
  font-weight: 600;
  line-height: 2rem;
  padding: 0 0.6rem;
  text-align: center;
  width: calc(100% / 7 - 10px);
`

const Days = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
`

type DayProps = {
  color?: string
  wintertraum?: boolean
  disabled: boolean
}

const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  @media (max-width: 767px) {
    width: calc(100% / 7 - 5px);
  }
  width: calc(100% / 7 - 10px);
`

const Day = styled.div<DayProps>`
  background-color: ${({ color }) => color ? color : 'transparent'};
  background-image: ${({ wintertraum }) => wintertraum ? `url(${winter})` : 'unset'};
  background-repeat: no-repeat;
  background-position: 90% 0%;
  background-size: auto 35px;
  border-radius: 6px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  line-height: 2rem;
  padding: 0.4rem 0.6rem;
  text-align: center;
  transition: color 150ms linear, background-color 150ms linear, padding 150ms linear;
  &:hover {
    padding: 0.4rem 0.2rem 0.4rem 1rem;
  }
`

const Line = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
`

const StyledSpan = styled.span`
  font-size: 30px;
  font-family: Serenity,sans-serif;
`

const App = () => {
  const [locale, setLocale] = useState('nl')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [weekdays, setWeekdays] = useState<string[]>([])
  const [data, setData] = useState<any[]>([])

  const getMonth = (): string => {
    const unformattedMonth = moment(selectedDate).locale(locale).format('MMMM')
    return `${unformattedMonth.charAt(0).toUpperCase()}${unformattedMonth.substr(1)}`
  }

  const getPreviousMonth = () => {
    setSelectedDate(moment(selectedDate).subtract(1, 'M').toDate())
  }

  const getNextMonth = () => {
    setSelectedDate(moment(selectedDate).add(1, 'M').toDate())
  }

  const getYear = (): string => moment(selectedDate).locale(locale).format('YYYY')

  const updateWeekdays = (locale: string) => {
    // Adapt months and weekdays
    const weekdays = moment.localeData(locale).weekdaysShort()
      .map((weekday) => {
        const weekdayWithoutDot = weekday.substr(weekday.length - 1) === '.' ? weekday.slice(0, -1) : weekday
        return `${weekdayWithoutDot.charAt(0).toUpperCase()}${weekdayWithoutDot.substr(1)}`
      })
    const [first, ...rest] = weekdays
    const orderedWeekdays = [...rest,first]
    setWeekdays(orderedWeekdays)
  }

  const onClickOpenPage = (data: any) => () => {
    const search = window.location.search.split('?')[1]
    let url
    if (locale === 'nl' && data && data.webshopUrlNL) {
      url = search != null ? `${data.webshopUrlNL}&${search}` : data.webshopUrlNL
      window.open(url, "_blank")
    } else if (locale === 'fr' && data && data.webshopUrlFR) {
      url = search != null ? `${data.webshopUrlFR}&${search}` : data.webshopUrlFR
      window.open(url, "_blank")
    }
  }

  const renderDays = (): React.ReactElement<typeof Day>[] => {
    let extraDays: Date[] = []
    const daysInView: Date[] = eachDayOfInterval({
      start: startOfWeek(startOfMonth(selectedDate), { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(selectedDate), { weekStartsOn: 1 })
    })
    if (daysInView.length === 35) {
      // We need to render 7 more days
      const lastDayInView = daysInView[35 - 1]
      const firstExtraDay = addDays(lastDayInView, 1)
      extraDays = times(7, (n: number) => addDays(firstExtraDay, n))
    }
    return daysInView.concat(extraDays).map((date: Date, idx: number) => {
      const formattedDate = moment(date).format('DD/MM/YYYY')
      const dateFound = data.find((d) => d.date === formattedDate)
      const today = isToday(date)
      const disabled = today || isPast(date) || dateFound == null
      const sameMonth = isSameMonth(date, selectedDate) 
      const t = disabled ? null : dateFound
      return (
        <DayContainer key={`day-${idx}`}>
          {sameMonth && (
            <>
              <Day
                className="text-sm md:text-xl"
                color={t?.color}
                wintertraum={t?.wintertraum}
                disabled={disabled}
                onClick={onClickOpenPage(t)}
              >
                {getDate(date)}
              </Day>
              {
                t 
                  ? t?.wintertraum
                    ? <div><span className="text-tinym md:text-base">{`€${t.price}`}</span><span className="text-tiny md:text-xs line-through decoration-red-600 text-red-600 ml-0.5 md:ml-1.5">{`€${t.price + 10}`}</span></div>
                    : <div><span className="text-tinym md:text-base">{`€${t.price}`}</span><span className="text-tiny md:text-xs line-through decoration-red-600 text-red-600 ml-0.5 md:ml-1.5">{`€${t.price + 5}`}</span></div> 
                  : <span className="h-4 md:h-6" />
              }
            </>
          )}
        </DayContainer>
      )
    })
  }

  useEffect(() => {
    updateWeekdays(locale)
    if (locale === 'nl') {
      document.title = "Wintertraum 22/23"
    } else {
      document.title = "Wintertraum 22/23"
    }
  }, [locale])

  useEffect(() => {
    const loadJson = async () => {
      const res = await fetch(process.env.PUBLIC_URL + '/dates.json')
      const d = await res.json()

      setData(d)
    }

    loadJson()
    const params = (new URL(document.location.href)).searchParams
    const lang = params.get('lang')
    if (lang === 'nl' || lang === 'fr') {
      setLocale(lang)
    }
  }, [])

  return (
    <>
      <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:w-9/12">
        <Navbar setLocale={setLocale} />
        <div>
          <div className="flex flex-wrap items-center justify-center gap-x-6 mb-6">
            <img alt="" className="w-40" src={logoFunticket} />
            <img alt="" className="w-40" src={logo} />
          </div>
        </div>
        {locale === 'nl' && (
          <div className="flex gap-x-6 justify-center">
            <div className="flex flex-col justify-between"> 
              <div><img alt="" className="h-16 md:h-24" src={logoNb} /></div>
              <div><img alt="" className="h-6 md:h-8" src={logoBvl} /></div>
            </div>
            <div className="grow-0">
              <img alt="" className="w-40" src={logoGva} />
            </div>
          </div>
        )}
        <div>
          {locale === 'nl' ? (
            <>
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">De goedkoopste tickets voor Phantasialand, altijd op Funticket.be!</p>
              <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Wintertraum 22-23: steeds € 10 korting per ticket op de standaardprijs</p> */}
              <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Momenteel worden geen kortingtickets verkocht via deze pagina.</p>
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">De Wintertraum tickets met € 10 korting zullen terug beschikbaar zijn vanaf 8 november.</p> */}
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Zomerseizoen 22: steeds € 5 korting per ticket op de standaardprijs</p> */}
            </>
          ) : (
            <>
              <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Aucun billet à prix réduit est actuellement en vente sur cette page.</p>
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Vos billets de Phantasialand au meilleur prix, toujours sur Funticket.be!</p> */}
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Les billets Wintertraum avec € 10 réduction seront à nouveau disponible à partir du 8 novembre.</p> */}
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Wintertraum 22-23: toujours 10 € de réduction par billet sur le tarif standard</p> */}
              {/* <p className="my-6 max-w-3xl mx-auto text-center text-xl font-semibold">Saison estivale 22: toujours 5 € de réduction par billet sur le tarif standard</p> */}
            </>
          )}
        </div>
        {/* {locale === 'nl' && (
          <div className="flex gap-x-6 justify-center mb-2.5">
            <div className="flex flex-col justify-between"> 
              <div><img alt="" className="h-16 md:h-24" src={logoNb} /></div>
              <div><img alt="" className="h-6 md:h-8" src={logoBvl} /></div>
            </div>
            <div className="grow-0">
              <img alt="" className="w-40" src={logoGva} />
            </div>
          </div>
        )} */}
        <div className="max-w-xl mx-auto">
          <CalendarHeader>
            <button
              type="button"
              className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={getPreviousMonth}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <StyledSpan>
              {
                `${getMonth()} ${getYear()}`
              }
            </StyledSpan>
            <button
              type="button"
              className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
              onClick={getNextMonth}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </CalendarHeader>
          <Weekdays>{weekdays.map((day: any, index) => <Weekday key={`day-${day}-${index}`} className="text-sm md:text-xl">{day}</Weekday>)}</Weekdays>
          <Days className="md:gap-x-2.5 md:gap-y-2.5">{renderDays()}</Days>
        </div>
        <Line />
        <div className="max-w-5xl mx-auto">
          <Legend locale={locale} />
        </div>
        <Line />
      </div>
      </div>
      <Footer locale={locale} />
    </>
  )
}

export default App

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import winter from '../images/wintertraum.png'
import CalendarRegular from './calendar-regular'
import CalendarBargain from './calendar-bargain'
import CalendarHoliday from './calendar-holiday'
import CalendarSold from './calendar-sold'
import CalendarNotAvailable from './calendar-notavailable'

type Props = {
  locale: string
}

const Winter = styled.div`
  background-color: transparent;
  background-image: url(${winter});
  background-repeat: no-repeat;
  background-position: 90% 0%;
  background-size: auto 30px;
  display: flex;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 5;
`

const Legend: React.FC<Props> = ({ locale }) => {
  const [data, setData] = useState<any>()
  useEffect(() => {
    const loadJson = async () => {
      const res = await fetch(process.env.PUBLIC_URL + '/price-color.json')
      const d = await res.json()

      setData(d)
    }

    loadJson()
  }, [])

  if (!data) {
    return null
  }

  return (
    <div className="flex flex-row flex-wrap gap-y-4">
      <div className="flex flex-row items-center w-full md:w-1/3">
        <div>
          <CalendarRegular color={data.regularPrice} />
        </div>
        <div className="flex flex-col px-3.5">
          <span className="font-semibold">{locale === 'nl' ? "Standaard prijs Phantasialand" : "Prix régulier Phantasialand"}</span>
          <div className="flex flex-col">
            <span className="text-xs"><em>{locale === 'nl' ? "Met € 5 extra funticket korting": "Avec € 5 de réduction supplémentaire de Funticket"}</em></span>
            <span className="text-xs"><em>{locale === 'nl' ? "Korting reeds verrekend in de prijs": "Remise déjà calculée dans le prix"}</em></span>
          </div>
        </div>
      </div>
      
      <div className="flex flex-row items-center w-full md:w-1/3">
        <div>
          <CalendarBargain color={data.bargainCheapestPrice} color1={data.bargainPrice} />
        </div>
        <div className="flex flex-col px-3.5">
          <span className="font-semibold">{locale === 'nl' ? "Promoprijs Phantasialand" : "Prix promotionnel Phantasialand"}</span>
          <div className="flex flex-col">
            <span className="text-xs"><em>{locale === 'nl' ? "Met € 5 extra funticket korting" : "Avec € 5 de réduction supplémentaire de Funticket"}</em></span>
            <span className="text-xs"><em>{locale === 'nl' ? "Korting reeds verrekend in de prijs": "Remise déjà calculée dans le prix"}</em></span>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center w-full md:w-1/3">
          <Winter/>
          <CalendarBargain color={data.bargainPrice} color1={data.regularPrice} />
        <div className="flex flex-col px-3.5">
          <span className="font-semibold">{locale === 'nl' ? "Phantasialand Wintertraum" : "Phantasialand Wintertraum"}</span>
          <div className="flex flex-col">
            <span className="text-xs"><em>{locale === 'nl' ? "Met € 10 extra funticket korting" : "Avec € 10 de réduction supplémentaire de Funticket"}</em></span>
            <span className="text-xs"><em>{locale === 'nl' ? "Korting reeds verrekend in de prijs": "Remise déjà calculée dans le prix"}</em></span>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center w-full md:w-1/3">
        <div>
          <CalendarSold  color={data.soldout} />
        </div>
        <div className="px-3.5">
          <span className="">{locale === 'nl' ? "De gewenste bezoekdatum is niet meer beschikbaar": "Épuisé" }</span>
        </div>
      </div>
      {/* <div className="flex flex-row items-center w-full md:w-1/3">
        <div>
          <CalendarNotAvailable  color={data.unavailable} />
        </div>
        <div className="px-3.5">
          <span className="">{locale === 'nl' ? "De gewenste bezoekdatum is niet meer beschikbaar / kan nog niet worden gekozen": "La date de votre choix n'est plus / pas encore disponible"}</span>
        </div>
      </div> */}
    </div>
  )
}

export default React.memo<Props>(Legend)
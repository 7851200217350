import React from 'react'
import { ArrowSmLeftIcon } from '@heroicons/react/solid'

type Props = {
  locale: string
}

const onClickNavigateToFunticket = () => {
  window.location.href = "https://www.funticket.be/"
}

const Footer: React.FC<Props> = ({ locale }) => (
  <div>
  <div className="max-w-7xl mx-0 md:mx-auto py-12 px-4 sm:px-6 lg:px-8 flex align-center justify-between md:w-9/12 gap-x-12 flex-wrap-reverse">
    <div className="flex justify-center space-x-6 items-center">
      <button
        type="button"
        className="h-12 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white mt-6"
        style={{ backgroundColor: '#FCB228' }}
        onClick={onClickNavigateToFunticket}
      >
        <ArrowSmLeftIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
        {locale === 'nl' ? "Terug naar Funticket" : "Retour à Funticket"}
      </button>
    </div>
    <div className="flex flex-col" style={{ width: '400px' }}>
      <p className="font-semibold">Contact</p>
      {locale === 'nl' 
        ? (
          <>
            <p className="text-sm mt-2">Voor praktische vragen over uw bezoek, mail <a className="font-semibold" href="mailto:info@funticket.be">info@funticket.be.</a></p>
            <p className="text-sm mt-2">Voor vragen over de betaling of levering van uw tickets, mail <a className="font-semibold" href="mailto:support@ticket.monster">support@ticket.monster.</a></p>
          </>) 
        : (
          <>
            <p className="text-sm mt-2">Pour toute question pratique concernant votre visite, veuillez envoyer un courriel à <a className="font-semibold" href="mailto:info@funticket.be">info@funticket.be.</a></p>
            <p className="text-sm mt-2">Pour toute question concernant le paiement ou la livraison de vos billets, veuillez envoyer un courriel à <a className="font-semibold" href="mailto:support@ticket.monster">support@ticket.monster.</a></p>
          </>
        )
      }
    </div>
  </div>
  </div>
)

export default React.memo<Props>(Footer)

import React from 'react'

type Props = {
  color?: string
  color1?: string 
}

const CalendarBargain: React.FC<Props> = ({ color, color1 }) => (
  <svg id="calendar-bargain" width="30px" height="30px" viewBox="0 0 30.2 29.904">
    <g transform="translate(-362 -2439)"><path d="M0,0H29.9V29.9H0Z" transform="translate(362 2439)" fill={color}/><rect width="15.2" height="29.904" transform="translate(377 2439)" fill={color1}/></g>
  </svg>
)

export default React.memo<Props>(CalendarBargain)
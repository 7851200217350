import React from 'react'

type Props = {
  color?: string 
}

const CalendarSold: React.FC<Props> = ({ color }) => (
  <svg id="calendar-sold" width="30px" height="30px" viewBox="0 0 30.2 30.2">
    <path d="M0,0H30.2V30.2H0Z" fill={color}/>
  </svg>
)

export default React.memo<Props>(CalendarSold)